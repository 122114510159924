import * as React from "react"
import CommonProduct, {toggleCtrl,specialMapping,fieldOverrides,headingLookup,headingIcons,hideHeadings,report_event} from "../components/commonPage"
import { Button, Snackbar, Container, Chip } from '@mui/material'
import LoadProducts from "../components/loadProducts"
import { navigate,Link } from "gatsby"
import dateFormat from "dateformat"
import $ from 'jquery';
import { Helmet } from "react-helmet"
import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item, useGallery } from 'react-photoswipe-gallery'
import VendorEmailPopup from "../components/vendorEmailPopup"

var _ = require('underscore')

export interface LandingProps {
  productFieldOrderMeta: any,
}

declare var Userback:any  // DO NOT SET.  It's a global variable.

const excluded_fields = {
  supportedLanguage: true,
//  subcategory: true
}

const order_by = {'relevance':"Relevance",'lastModifiedDate':"Last Modified",'name': "Name - Alphabetical"}


const getValidUrl = (url = "") => {
  let newUrl = window.decodeURIComponent(url);
  newUrl = newUrl.trim().replace(/\s/g, "");

  if(/^(:\/\/)/.test(newUrl)){
      return `http${newUrl}`;
  }
  if(!/^(f|ht)tps?:\/\//i.test(newUrl)){
      return `http://${newUrl}`;
  }

  return newUrl;
};

const validation_menu = [
  {
    name:'Safe',
    comingSoon:false
  },
  {
    name:'Evidence-Based',
    comingSoon:false,
  },
  {
    name:'Inclusive',
    comingSoon:false,
  },
  {
    name:'Usable',
    comingSoon:false,
  },
  {
    name:'Interoperable',
    comingSoon:false,
  },
]

const validationFields = {
  'Interoperable': ['activeIntegrationPartnershipsUrl'],
  'Safe': ['spdcNationalDataPrivacyAgreementLabel','privacyStatement','dataUseDescription','policies','learnerData'],
  'Evidence-Based': [],
  'Usable': [],
  'Inclusive': [],
}

let area_description = [
  <>Privacy validation ensures a product is in compliance with necessary privacy laws and has shared information about their policies for keeping learning information safe and secure. <span onClick={()=>{navigate("/validations/#privacy")}}>Learn more about privacy</span>.</>,
  <>Validations around research and evidence demonstrate how a product was built upon a rigorous, research-based foundation or has been studied to determine its effectiveness, to better meet teaching and learning needs.<br/><span onClick={()=>{navigate("/validations/#research")}}><br/>Learn more about research and evidence</span>.</>,
  <>Validations on accessibility show how a product aligns to accessibility standards and includes ways for learners with disabilities and learning differences to be able to  effectively perceive, understand, navigate and interact with the learning product.<br/><span onClick={()=>{navigate("/validations/#accessibility")}}><br/>Learn more about accessibility</span>.</>,
  <>Validations on usability demonstrate that a product is built with deep understanding of the whole person, especially learners' and teachers' needs, contexts, competencies and backgrounds.<br/><span onClick={()=>{navigate("/validations/#usability")}}><br/>Learn more about usability</span>.</>,
  <>Interoperability is the seamless, secure and controlled exchange of data between learning technology solutions.<br/><span onClick={()=>{navigate("/validations/#interoperability")}}>Learn more about interoperability</span>.</>,
]

interface StaticProps {
  product: any,
  clickFunction: any,
  ts_date: any,
  my_list_state: any,
  showMoreSS: any
}

function GalleryContent( props ) {
  const {product, showMoreSS, setOpenFunction} = props
  let { open } = useGallery()
  setOpenFunction(open)

  if (!product.screenshots) return null

  return product.screenshots.map( (screenshot, ssIndex)=> {
      return <Item
          key={"screenshotItem_"+ssIndex}
          content={<img src={screenshot.mediaUrl} />} 
          children={({ ref, open }) => (
            <a key={"screenshot_"+ssIndex} onClick={open} className={"property screenshot" + ((showMoreSS || ssIndex<3)?'':' hide')}><img ref={ref} src={screenshot.mediaUrl} /></a>
          )}
        />
    })

}

class SimpleInformation extends React.Component<StaticProps> {
  compareProps:any = null
  constructor(props)
  {
    super(props)
  }



  shouldComponentUpdate(nextProps, nextState) {
    let retVal = true
    if (!!this.compareProps && !!this.compareProps.product && (this.compareProps.showMoreSS==nextProps.showMoreSS && this.compareProps.my_list_state==nextProps.my_list_state &&
      Object.keys(this.compareProps.product).length==Object.keys(nextProps.product).length
      ) )
    {
      retVal = false
    }
    this.compareProps = nextProps
    return retVal
  }

  render() {
    let {parent,my_list_state,product,clickFunction,ts_date,showMoreSS} = this.compareProps || this.props

    let textFieldIds = [
      {
        fieldId: 'publicDescription',
        fieldHeading: null,
      },
      {
        fieldId: 'intendedUsageDescription',
        fieldHeading: 'Intended Use',
      },
      {
        fieldId: 'softwareRequirements',
        fieldHeading: 'Software Requirements',
      },
      {
        fieldId: 'pricingDescription',
        fieldHeading: 'Pricing Description',
      },
      {
        fieldId: 'productTrainingCostDescription',
        fieldHeading: 'Product Training Cost Description',
      },
      {
        fieldId: 'disclaimer',
        fieldHeading: 'Disclaimer',
      },
    ]
    
    let mainSSclick:any
    const galleryContent = <GalleryContent product={product} showMoreSS={showMoreSS} setOpenFunction={(open) => {mainSSclick=()=>{open(0)}}}/>
    
    return <div id="product_focus">
    <div key={'productwrap_'+product.productKey}>
      <img className="logo" src={product.image_url} />
      <strong>{product.name}</strong>
      <div className="product_by">by {product.organizationName}</div>

      <div className="clear mobile_only"/>
      <div className="listButton mobile_only">
          <Button className={my_list_state?'selected':'deselected'} onClick={clickFunction} variant="outlined"><i className="fal fa-plus"></i>{my_list_state?'Added':'Add to my list'}</Button>
      </div>
      <span className="property mobile_only">ULTID: <strong>{product.ultid}</strong></span>
      <span className="property updated mobile_only"><i className="fal fa-check-circle"></i> Updated: {ts_date}</span>

      {
        (product?.screenshots?.length>0) &&
        <img className="screenshot" alt="Screenshot" id="mainSS" src={product.screenshots[0].mediaUrl} onClick={()=>{mainSSclick()}}/>
      }
      {
        textFieldIds.map( (textField, index) => {
          if (!!product[textField.fieldId])
            return <div key={textField.fieldId} className="moreTextArea" id={textField.fieldId}>
                {
                  textField.fieldHeading &&
                  <strong>{textField.fieldHeading}</strong>
                }
                <p className=" desc">{product[textField.fieldId]}</p>
                <div className="moreLink" onClick={()=>{$('#'+textField.fieldId).toggleClass('more')}}><span className="down_arrow"></span><span className="up_arrow"></span></div>
              </div>
    
        })
      }

      <Button className="product_btn" onClick={()=>{ window.open( getValidUrl( product.mainEntityOfPage ) ) }} variant="outlined">Product website</Button>
      
      <div className="clear dividingLine"/>

      <h2>Videos + Screenshots</h2>

      {
        (!!product.videos) &&
        product.videos.map( (video, vIndex)=> {
          return <a href={video.mediaUrl} target="_blank" key={"video_"+vIndex} className="property video">{video.fileName}</a>
        })
      }
      <Gallery options={{
        imageClickAction:"close"
      }}>
        { galleryContent }
      </Gallery>
      <div className="clear"/>
      {
        (product.screenshots?.length>3 ) &&
        <div className="moreLink" onClick={()=>{parent.setState({showMoreSS:!showMoreSS})}}>Show {(showMoreSS)?'less':'more'} screenshots<span className="down_arrow"></span></div>
      }

    </div>
  </div>

  }

}


// const { application_api_key, application_id, product_url,indexName, siteUrl,port } = useSiteMetadataProduct()
class ProductPage extends CommonProduct {
  RenderedSimpleSection:SimpleInformation = null
  simpleSection:any
  recordedId:any


  // async testAsync(){
  //   await axios.get(data.site.siteMetadata.product_url+'metadata')
  // }

  constructor(props)
  {
    super(props)

    this.simpleSection = new SimpleInformation(props)

    this.isBrowser = typeof window !== "undefined"
    this.state.showMoreSS = false
    this.state.showValidations = false
    this.state.validationMenu = 0
    this.state.vendorEmailPopup = false
    this.state.myProduct = []

    if (this.isBrowser)
    {
        if (!!location.search)
        {
          let termsForUltid = location.search.split('?ultid=',2)
          
          if (termsForUltid.length==2)
          {
            let term = termsForUltid[1]
            if (term[term.length-1] === '/') {
              term = term.substring(0, term.length-1)
            }
            this.state.ultid = decodeURI(term)
          }

          let terms = location.search.split('?recordId=',2)
          if (terms.length==2)
          {
            let term = terms[1]
            if (term[term.length-1] === '/') {
              term = term.substring(0, term.length-1)
            }
            this.state.recordId = decodeURI(term)
          }
        }
  
        this.state.productFieldOrderMeta = (!!this.myStorage?.productFieldOrderMeta)?JSON.parse(this.myStorage.productFieldOrderMeta):{}
    }

  }

  loadProducts(myProduct, product_url, siteUrl)
  {


      //for showing banner on first time visiting
      if (!!this.state.firstTime) 
        this.setState({showingBanner:true});
    
      this.setState({myProduct,product_url,siteUrl,firstTime:false});
 
  }
  componentDidMount() {
    super.componentDidMount()
    window.addEventListener('scroll', this.handleScroll.bind({elementName:"validations_window"}));
  }

  componentWillUnmount() {
    super.componentWillUnmount()
    window.removeEventListener('scroll', this.handleScroll.bind({elementName:"validations_window"}));
  }


  componentDidUpdate(prevProps, prevState)
  {
    super.componentDidUpdate(prevProps, prevState)
    $('.moreTextArea p').each((index, desc)=>{
      let floorHeight = Math.floor($(desc).height())
      if (floorHeight== $(desc)[0].scrollHeight)
      {
        $($(desc).parent()).addClass('noMore')
      }
    })
  }

  handleCloseBannerFirstVisit = (event?: React.SyntheticEvent, reason?: string) => {
    this.setState({showingBanner:false})
  }

  firstVisitBanner()
  {
    return !!this.state.showingBanner?(
      <Container id="banner_first_visit">
      Welcome to the EdSurge Index.  We're growing fast, so please try out our search tools to find other products of interest!
          <div id="close_button_banner">
            <Button 
              onClick={() => {
                this.handleCloseBannerFirstVisit();
              }}
            >
              Close
            </Button>
          </div>
      </Container>
    ):false;
  }

  render() {
    
    let {mv_lookups,myProduct:products, my_product_keys,userData, vendorEmailPopup} = this.state

    let metaLoaded = this.metaLoaded.bind(this)
    let loadProducts = this.loadProducts.bind(this)

    let filterLookup = {}

    let filterJSONValues = JSON.parse( (this.isBrowser && window.localStorage['filter']) || "{}" )
    for (let complexKey of Object.keys(filterJSONValues))
    {
      let [sel, fieldName, keyStr] = complexKey.split('_')
      if (filterJSONValues[complexKey])
      {
        filterLookup[keyStr] = mv_lookups.products[fieldName][keyStr]
      }
    }
    const logged_in = (userData && userData.login)?true:false

    let myProduct:any = {}
    if (products.length>0)
    {
        myProduct = products[0]

        if (logged_in && this.recordedId != myProduct.productKey)
        {
          this.recordedId = myProduct.productKey
          report_event('interest', {jobRole:userData.jobRole,organizationType:userData.organizationType,value:myProduct.productKey})
        }
    }

    let productIds:any = []
    if (!!this.state.recordId)
    {
        productIds.push(this.state.recordId)
    }

    let product_key = 'product_'+myProduct.productKey
    let my_list_state = this.state[product_key]
    let my_new_state = (!!my_list_state)?null:myProduct.name
    let updateMyList = this.updateMyList.bind(this)
    let bound_values:any = {unique_id:product_key,state:my_new_state,controller:this,save_group:'my_list',onUpdate:updateMyList,
        eventTrack: {type:'Add to my list', properties:{product_key:myProduct.productKey,state:(my_new_state)?'add':'remove'}},
      }
    let toggler:toggleCtrl = new toggleCtrl(bound_values)
    let clickFunction = toggler.changeSelection.bind(toggler)
    let vendorClickFunction = ()=>{this.setState({vendorEmailPopup:true})}
    if (!logged_in) clickFunction = ()=>{this.setState({showLogin: true})}

    let ts = myProduct.lastModificationTimestamp
    let ts_date = ''
    if (!!ts)
    {
      let date = new Date(ts)
      ts_date = dateFormat(date,"mmm d, yyyy")
    }

    const edLvlLookup = this.state?.mv_lookups?.products?.educationalLevel

    let headingKeys = Object.keys(headingLookup)

    let fieldMeta:any = {}
    if (!!this.state?.productFieldOrderMeta)
    {
      let pmo = this.state.productFieldOrderMeta
      if (!!pmo?.keys)
      {
        for (let key of pmo.keys)
        {
          let meta_list = pmo[key]
          for (let meta of meta_list)
            if (meta.tableName=='products')
            {
              fieldMeta[meta.columnName] = meta.displayName
            }
        }
      }
    }

    let simpleProps = {
      clickFunction:clickFunction,
      product:myProduct,
      ts_date:ts_date,
      my_list_state:my_list_state,
      showMoreSS:this.state.showMoreSS,
      parent:this,
      key:"simpleSection",
    }
    if (this.simpleSection.shouldComponentUpdate(simpleProps,this.state))
    {
      this.RenderedSimpleSection = this.simpleSection.render()
    }

    if (!!this.state.ultid){
      if (!(window.location.href.includes('/ultid/'))){
        if (this.state.ultid?.endsWith("/")){
          history.replaceState({}, '', "../product/ultid/"+this.state.ultid);
        }else{
          history.replaceState({}, '', "../product/ultid/"+this.state.ultid+"/");
        }
        
      }
    }


    let hasAnyValidations = false;
    let hasValidationInfo = Object.fromEntries(
      Object.keys(validationFields).map((name) => {
        let hasValidations = false;

        !hasValidations && myProduct.validations && myProduct.validations.map( (validation, v_index) => {
          if (validation.area==name)
          {
            console.log(validation.area)
            hasValidations = true
            hasAnyValidations = true
          }
        })
        return [name,hasValidations]
      })
    )

    const dictForValidFields=mv_lookups!.products
    
    // New features
    let accessUserLabels = []
    let accessUserLookup = {}
    let accessUserMatrix = {}

    let sdpcAgreement ={}

    // We need this every time now to see if the Validation button is enabled.
    if (!!mv_lookups && !!mv_lookups.products && !!mv_lookups.products.accessAggregate && !!mv_lookups.products.accessDemographicData && !!mv_lookups.products.accessEducationalRecord) {
      let row = 0
      for (let key of Object.keys(mv_lookups.products.accessAggregate)) {
        if (!Number.isNaN(parseInt(key))) {
          let value = mv_lookups.products.accessAggregate[key]
          accessUserLookup[key] = row
          accessUserMatrix[row] = {}
          accessUserLabels.push(value)
          row++
        }
      }
      const addLookupData = (lookupName) => {
        row = 0;
        for (let key of Object.keys(mv_lookups.products[lookupName])) {
          if (!Number.isNaN(parseInt(key))) {
            accessUserLookup[key] = row
            row++
          }
        }
      }

      addLookupData('accessDemographicData')
      addLookupData('accessEducationalRecord')

      const processColumn = (field, col) => {
        if (myProduct[field]) {
          for (let info of myProduct[field]) {
            row = accessUserLookup[info.lookupItemKey]
            // accessUserMatrix[row][field] = true
            accessUserMatrix[row][col] = true
            //hasValidationInfo['Safe'] = true
          }
        }
      }

      processColumn('accessEducationalRecord', 0)
      processColumn('accessDemographicData', 1)
      processColumn('accessAggregate', 2)

      // spdc
      if (!!(myProduct?.spdcNationalDataPrivacyAgreementUrl)){
        sdpcAgreement={label:myProduct?.spdcNationalDataPrivacyAgreementLabel||"SDPC Agreement", url:myProduct?.spdcNationalDataPrivacyAgreementUrl}
      }

    }

    const CLMSLabels = []
    const CSISLabels = []
    const ASSOLabels = []
    const AuthLabels = []
    const CostLabels = []

    const lookupProcessor = (varname, labels, pushIt) => {
      const keys= myProduct && myProduct[varname]
      if (!!keys)
        for (let ck of keys){
          if (pushIt) { labels.push(({label:ck.value, id:"clms_chips_"+ck.lookupItemKey})) }
          //hasValidationInfo['Interoperable'] = true
        }
    }
    lookupProcessor('compatibleLearningManagementSystems',CLMSLabels, this.state.validationMenu==4 )
    lookupProcessor('sisCompatibility',CSISLabels, this.state.validationMenu==4 )
    lookupProcessor('interoperabilityAuthenticationSSO',ASSOLabels, this.state.validationMenu==4 )
    lookupProcessor('interoperabilityAuthorization',AuthLabels, this.state.validationMenu==4 )
    lookupProcessor('interoperabilityCost',CostLabels, this.state.validationMenu==4 )

    // adding array for Badges and Certifications for each validation tab.
    const BadgesCertLabels=[
      'These point to any rating, accomplishment or earned credential on the degree to which the product is safe and secure. These are validated by third parties.',
      'These point to any rating, accomplishment or earned credential on the degree to which the product is research-backed. These are validated by third parties.',
      '',
      'These point to any rating, accomplishment or earned credential on the degree to which the product was built with user-centred design principles. These are validated by third parties.',
      'These earned credentials show the degree to which the product is interoperable. These are validated by third parties.',
    ]

    const validations_open = this.state['validations_heading']

    if(vendorEmailPopup) {
      this.state.dynamicPopup = <VendorEmailPopup
        closeFn={()=>{this.setState({vendorEmailPopup:false})}}
        email={userData && userData.email || ''}
        form_target={this.vendor_form_target}
        ultid={this.state.ultid}
        product={myProduct}
        />
    }
    if (this.state.showValidations) {
      this.state.dynamicPopup = <div id="validation_popup">
        <div id="validations_window">
          <div className="header">Validations<i onClick={()=>{this.setState({showValidations:false})}} className="fal fa-times"></i></div>
          <div className={"validationInside badge"+this.state.validationMenu}>
            <div className="productInfo">
              <img className="logo" src={myProduct.image_url} />
              <strong>{myProduct.name}</strong>
            </div>
            <ul>
              {
                validation_menu.map((menuItem,index)=>{
                  return <li key={menuItem.name+"_v_hdr"} className={(this.state.validationMenu==index)?'selected':''} onClick={()=>{this.setState({validationMenu:index})}}>
                    {
                      (menuItem.comingSoon) &&
                      <i>{menuItem.name}</i>
                    }
                    {
                      (!menuItem.comingSoon) && menuItem.name
                    }
                  </li>
                })
              }
            </ul>
            <div className="clear"/>

            {
              
              (this.state.validationMenu<6) &&
              <div className="subsectionText">
                <div className="subsection">Badges &amp; Certifications
                
                   <i className="fal fa-question-circle tooltipForOptions leftColumnQuestionMark"></i>
                    <div className="popupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack leftColumnPopupTextboxClass" id={"leftColumnPopupTextboxBlack"+this.state.validationMenu} >{ BadgesCertLabels[this.state.validationMenu]??''}</div> </div>

                  </div>

                {myProduct.validations && myProduct.validations.map( (validation, v_index) => {
                  if (validation.area==validation_menu[this.state.validationMenu].name)
                  return <div key={"badge_"+v_index} className="v_badge">
                    <a href={validation.url}  target="_blank">
                      <img src={validation.image_url} alt={validation.name}/>
                      {validation.label}
                      <div className="clear"/>
                    </a>
                  </div>
                })}
                <div className="clear"/>
              </div>
            }
            
            <div id="area_desc">
              {area_description[this.state.validationMenu]}
            </div>
            
            {
              (this.state.validationMenu==2) &&
              <div className="subsectionText full">
              </div>
            }
            {
              (this.state.validationMenu==3) &&
              <div className="subsectionText full">
              </div>
            }
            {
              (this.state.validationMenu==1) &&
              <div className="subsectionText full">
              </div>
            }

            {
              (this.state.validationMenu==4) &&
              <>
                <div className="clear dividingLine"/>
                <div className="header"><i>The following information has been supplied by the vendor</i></div>
                {
                  <>
                    <div className="subsectionText chipSection">
                      <div className="subsection">Compatibility
                      
                        <i className="fal fa-question-circle tooltipForOptions leftColumnQuestionMark"></i>
                          <div className="popupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack leftColumnPopupTextboxClass" id="compatibilityPopupTextBox" >This points to the subset of products with which this product has active, successful integrations.</div> </div>

                      </div>
                      <em>Active Integrations</em><br/>
                      {
                      ( myProduct.activeIntegrationPartnershipsUrl ) && <>
                          <Link to={myProduct.activeIntegrationPartnershipsUrl}>Active Integration Partnerships</Link><br/><br/>
                        </>
                      }
                      {
                      ( !(myProduct.activeIntegrationPartnershipsUrl) ) && <>
                          Information not supplied.<br/><br/>
                        </>
                      }




                      <em>Compatible Learning Management Systems</em><br/>
                      {
                        (CLMSLabels.length>0) && <>
                          {
                            CLMSLabels.map((x)=> <span className="extraFieldChip chipCLMS" > <Chip label={x.label} key={x.lookupItemKey}/></span>)
                          }
                          <br/><br/>
                        </>
                      }
                      {
                        (CLMSLabels.length==0) && <>
                         Information not supplied.
                          <br/><br/>
                        </>
                      }


                      <em>Compatible Student Information Systems</em><br/>
                      {
                        (CSISLabels.length>0) && <>
                          
                          {
                            CSISLabels.map((x)=> <span className="extraFieldChip chipCSIS" > <Chip label={x.label} key={x.lookupItemKey}/></span>)
                          }
                        </>
                      }
                      {
                        (CSISLabels.length==0) && <>
                         Information not supplied.
                          <br/><br/>
                        </>
                      }

                    </div>
                    <div className="clear dividingLine"/>
                  </>
                }

                
                <div className="subsectionText">
                  <div className="subsection">Authentication SSO
                    <i className="fal fa-question-circle tooltipForOptions leftColumnQuestionMark"></i>
                      <div className="popupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack leftColumnPopupTextboxClass" id="authenticationSSOPopupTextBox" >This indicates the ways in which the product validates who the users are and whether they provide a single sign-on solution.</div> </div>
                  </div>
                  {
                    (ASSOLabels.length>0) && 
                    ASSOLabels.map((x)=> <span className="extraFieldChip chipASSO" > <Chip className="chipASSOText" label={x.label} key={x.lookupItemKey}/></span>)
                  }
                  {
                    (ASSOLabels.length==0) && 
                    <>Information not supplied.</>
                  }
                  
                </div>
                <div className="clear dividingLine"/>

                <div className="subsectionText chipSection">
                  <div className="subsection">Authorization
                    <i className="fal fa-question-circle tooltipForOptions leftColumnQuestionMark"></i>
                    <div className="popupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack leftColumnPopupTextboxClass" id="authorizationPopupTextBox" >This indicates how the product gives users permission to access specific features and functionalities within the product</div> </div>
                  </div>
                  {
                    (AuthLabels.length>0) && 
                    AuthLabels.map((x)=> <span className="extraFieldChip chipAuth" > <Chip label={x.label} key={x.lookupItemKey}/></span>)
                  }
                  {
                    (AuthLabels.length==0) && 
                    <>Information not supplied.</>
                  }
                  
                </div>
                <div className="clear dividingLine"/>
                
                <div className="subsectionText chipSection">
                  <div className="subsection">Cost</div>
                  {
                    (CostLabels.length>0) && 
                    CostLabels.map((x)=> <span className="extraFieldChip chipAuth" > <Chip label={x.label} key={x.lookupItemKey}/></span>)
                  }
                  {
                    (CostLabels.length==0) && 
                    <>Information not supplied.</>
                  }
                  
                </div>
                <div className="clear dividingLine"/>

                <div className="subsectionText">
                  <div className="subsection">Cost Description</div>
                  { (((myProduct.costDescription)??"").trim() == "")?"Information not supplied.":myProduct.costDescription }<br/>
                </div>
                <div className="clear dividingLine"/>
              </>
            }
            {
              (this.state.validationMenu==0) &&
              <>
                <div className="clear dividingLine"/>
                <div className="header"><i>The following information has been supplied by the vendor</i></div>
                <div className="subsectionText">
                  <div className="subsection">Privacy Commitments and Agreements</div>
                  <p>
                    <em>SDPC National Data Privacy Agreement</em>
                    <i className="fal fa-question-circle tooltipForOptions sdpcQuestionMark"></i>
                    <div className="popupBlack" id="midColumnPopupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack" id="spdcPopupTextBoxBlack">This is a link to an example of an existing SDPC National Data Privacy Agreement. For more information go to <Link to="https://sdpc.a4l.org" target="_blank">https://sdpc.a4l.org</Link></div> </div>
                    <br/>

                    {
                      (Object.keys(sdpcAgreement).length === 0)?"Information not supplied.":<><Link to={sdpcAgreement.url}>{sdpcAgreement.label}</Link><br/></>
                    }
                    
                  </p>
                  <p>
                    <em>Privacy Policies</em>
                    <i className="fal fa-question-circle tooltipForOptions sdpcQuestionMark"></i>
                    <div className="popupBlack" id="midColumnPopupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack" id="privacyPolicyTextBoxBlack">These are links to the solution providers various privacy policies and or data breach protocols.</div> </div>
                    <br/>
                    <div className="privacyPolicies">
                    {
                      (myProduct.policies.length > 0)?
                      (myProduct.policies.map((policy) => <> 
                        <Link to={policy.url} target="_blank">{policy.urlTitle}</Link>
                      </>)):
                      "Information not supplied."
                    }
                    </div>

                  </p>
                  <p>
                    <em>Privacy Statement</em><br/>
                    { (myProduct.privacyStatement)?(myProduct.privacyStatement):"Information not supplied." }
                  </p>
                </div>
                <div className="clear dividingLine"/>
                
                <div className="subsectionText learnerSection">
                  <div className="subsection">Learner Data</div>
                  <p>
                    <em>Learner Data Collected</em>
                    <i className="fal fa-question-circle tooltipForOptions sdpcQuestionMark"></i>
                    <div className="popupBlack" id="learnerDataPopupBlack" ><div className="popupPointerBlack">&nbsp;</div> <div className="popupTextBoxBlack" id="learnerDataPopupTextboxBlack">The learner data captured and stored within the learning technology product. This includes any personally Identifiable Information (PII) and other non-public information as well as metadata and user content.</div> </div>
                    <br/>
                    {
                      (myProduct.learnerData)?
                      (myProduct.learnerData.map( data => {
                        return <Chip key={"learner_"+data.lookupItemKey} label={data.value}/>
                      })): "Information not supplied."
                    }
                  </p>
                  <p>
                    <em>Data Use Description</em><br/>
                    { (myProduct.dataUseDescription)?
                    myProduct.dataUseDescription
                    :"Information not supplied." }
                  </p>
                </div>
                <div className="clear dividingLine"/>

                <div className="subsectionText accessSection hide_mobile">
                  <div className="subsection">Access</div>
                  <em>Data Access by user role within the tool</em>
                  <table className="accessTable">
                    <tr>
                      <th>User Role</th>
                      <th>Learner's educational records</th>
                      <th>Learner's demographic data</th>
                      <th>De-identified data</th>
                    </tr>
                    <tr className="dataAccessHeaderRow">
                          <td colSpan={4}><div className="clear dividingLine"></div></td>
                    </tr>
                    {
                      accessUserLabels.map( (label,row) => {
                        return <><tr>
                          <td>{label}</td>
                          <td>{ (accessUserMatrix[row][0]==true)?<i className="fas fa-check-circle"></i>:<><i className="fal fa-exclamation-circle"></i><div className="asterisk-exclamation">*</div></> }</td>
                          <td>{ (accessUserMatrix[row][1]==true)?<i className="fas fa-check-circle"></i>:<><i className="fal fa-exclamation-circle"></i><div className="asterisk-exclamation">*</div></> }</td>
                          <td>{ (accessUserMatrix[row][2]==true)?<i className="fas fa-check-circle"></i>:<><i className="fal fa-exclamation-circle"></i><div className="asterisk-exclamation">*</div></> }</td>
                        </tr>
                        <tr className="dataAccessTRow">
                          <td colSpan={4}><div className="clear dividingLine"></div></td>
                        </tr>
                        </>
                      })
                    }
                    <tr>
                      <td></td>
                    </tr>
                    <tr id="footerPrivacyTR">
                      <td colSpan={4}><span id="footerPrivacyTab">* Access unknown; Information not provided.</span></td>
                    </tr>
                  </table>
                </div>
                
              </>
            }
            <div className="clear"/>
          </div>
          <div className="feedback">
            Have a suggestion for us? <span onClick={()=>{Userback.open()}}>Let us know.</span><br/>
          </div>

        </div>
      </div>
    }
    else {
      if (!vendorEmailPopup) {
        this.state.dynamicPopup = null
      }
    }

    let BaseComponent = this.BaseComponentRender.bind(this)

    return (
    <>
      <BaseComponent menu="single_product" title={myProduct.name+" | EdTech Index"} callback={metaLoaded} my_product_keys={my_product_keys}>
      {this.firstVisitBanner()}

      <LoadProducts 
              onChange={loadProducts}
              state={this.state}
              recordIds={productIds}
              ultid={this.state.ultid}
              initState={'initProduct'}
              ></LoadProducts>

      {this.RenderedSimpleSection}

      <div id="extras">
          <span className="property hide_mobile">ULTID: <strong>{myProduct.ultid}</strong></span>
          <span className="property updated hide_mobile"><i className="fal fa-check-circle"></i> Updated: {ts_date}</span>

          <div id="validations">
          {this.Collapsable("Validations", "validations_heading", validations_open ,headingIcons['product_validation_heading'])}
          { 
            hasAnyValidations && <strong>See how this product aligns:</strong> || <strong>Product not currently validated</strong>
          }
          {
            (validations_open) && <>
              <Button className={`validation_btn${hasValidationInfo['Safe']?' hasInfo':''}`} disableRipple={true} onClick={()=>{this.setState({showValidations:true,validationMenu:0});report_event('Show Validation',{name:'Safe'});}} variant="outlined"><i className="fal fa-user-lock"></i>Safe</Button>
              <Button className={`validation_btn${hasValidationInfo['Evidence-Based']?' hasInfo':''}`} disableRipple={true} onClick={()=>{this.setState({showValidations:true,validationMenu:1});report_event('Show Validation',{name:'Evidence-Based'});}} variant="outlined"><i className="fal fa-file-search"></i>Evidence-Based</Button>
              <Button className={`validation_btn${hasValidationInfo['Inclusive']?' hasInfo':''}`} disableRipple={true} onClick={()=>{this.setState({showValidations:true,validationMenu:2});report_event('Show Validation',{name:'Inclusive'});}} variant="outlined"><i className="fal fa-universal-access"></i>Inclusive</Button>
              <Button className={`validation_btn${hasValidationInfo['Usable']?' hasInfo':''}`} disableRipple={true} onClick={()=>{this.setState({showValidations:true,validationMenu:3});report_event('Show Validation',{name:'Usable'});}} variant="outlined"><i className="fal fa-phone-laptop"></i>Usable</Button>
              <Button className={`validation_btn${hasValidationInfo['Interoperable']?' hasInfo':''}`} disableRipple={true} onClick={()=>{this.setState({showValidations:true,validationMenu:4});report_event('Show Validation',{name:'Interoperable'});}} variant="outlined"><i className="fal fa-random"></i>Interoperable</Button>
            </>
          }
          </div>
          {
            headingKeys.map((headingFieldName,headingIndex)=>{
              if (hideHeadings[headingFieldName]) {
                return null
              }
              let product_heading = 'product_'+headingFieldName
              let childFields = (!!this.filterTree[headingFieldName])?Object.keys(this.filterTree[headingFieldName]):[]
              let empty = true
              return <div key={'product_row_section_'+headingFieldName}>
                <div className="dividingLine"/>
                {this.Collapsable(headingLookup[headingFieldName], product_heading, !!this.state[product_heading],headingIcons[headingFieldName])}
                {
                  (!!this.state[product_heading]) &&
                  <div id="product_productCategories_row">
                  {
                    (products.length>0) &&
                    products.map( (product,index) => {
                      let mv_lookup = this.createLookupFromArray(product.mv_items)

                      let NormalFields:any = null
                      for (let fieldName of childFields)
                      {
                        let SpecialFields:any = null
                        let fieldLabel = fieldOverrides[fieldName] || this.filterTree[headingFieldName][fieldName].displayName
                        let type = this.filterTree[headingFieldName][fieldName].type
                        if (fieldName=='showPoweredBy')
                        {
                          continue
                        }
                        if (fieldLabel==true)
                        {
                          fieldLabel = fieldMeta[fieldName]
                        }
                        if (type == "MLI")
                        {
                          let exceptions:any = null
  
                          if (fieldName=='educationalLevel' && !!product[fieldName] && product[fieldName].length>0)
                          {
                            SpecialFields = Object.keys(specialMapping).map((lookupKey,index)=>{
                                let key = edLvlLookup[lookupKey]
                                return <span key={product.productKey+"grade_ed_level_"+lookupKey} className={"property ed_grade"+((!!mv_lookup[key])?' selected':'')}>{specialMapping[lookupKey]}</span>
                              })
                            exceptions = specialMapping
                          }

                          let fieldValues = this.showProperty(fieldName, mv_lookup, exceptions)

                          let showPoweredByAWS = false

                          if (fieldName=='productType')
                          {
                            showPoweredByAWS = myProduct.showPoweredBy == 1
                          }

                          if (!!fieldValues)
                          {
                            empty = false
                            NormalFields = <>
                            { showPoweredByAWS && <>
                                <a id="poweredByAWS" href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws.png" alt="Powered by AWS Cloud Computing" /></a>
                                <div className="clear"/>
                              </> }
                            {NormalFields}
                            <div key={product.productKey+"heading_child_"+fieldName}>
                              <h3>{fieldLabel}</h3>
                              {SpecialFields}
                              {
                                (SpecialFields!=null) &&
                                <div className="clear"/>
                              }
                              {fieldValues}
                            </div>
                          </>
                        }
  
                        }
                        else
                        if (type == "DataTypes.INTEGER(1)")
                        {
                          NormalFields = <>
                              {NormalFields}
                              <h3>{fieldLabel}</h3>
                              <span className="property mv_item">{(product[fieldName]==1)?'Yes':((product[fieldName]==0)?'No':'N/A')}</span>
                            </>

                        }

                      }

                      return <div key={'product_productCategories_'+product.productKey}>
                        {NormalFields}
                        </div>
                    })
                  }
                  </div>
                }
                {
                  (!!this.state[product_heading] && empty) &&
                  <>
                  <br/>
                  Information not supplied.
                  </>

                }
              </div>
            })
          }

      </div>
      <div className="staticFooter">
              <Button className={my_list_state?'selected':'deselected'} onClick={clickFunction} variant="outlined"><i className="fal fa-plus"></i>{my_list_state?'Added':'Add to list'}</Button>
              <Button className='contactVendor' onClick={()=>{report_event('contactVendor', {product_key:myProduct.productKey});vendorClickFunction()}} variant="outlined">Contact Vendor</Button>
              { myProduct.buyNowUrl && <a className='buyNow' onClick={()=>{report_event('buyNowUrl', {product_key:myProduct.productKey});}} href={myProduct.buyNowUrl} target="_blank">Buy now</a> }
      </div>

      </BaseComponent>
    </>
    )
  }
}


export default ProductPage

